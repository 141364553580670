<template>
  <div>
    <!--<p>info@kingsleague.ca</p>
    <p>514-608-2297</p>-->
    <XNav :content="[{name:'Contact Us'}]" />

    <div class="box">
      <h2>{{$t('contactUs.contactUs')}}</h2>
      <div class="contact-info">
        <p>{{$t('contactUs.email')}} : <a href="mailto:info@kingsleague.ca">info@kingsleague.ca</a></p>
        <p>{{$t('contactUs.phone')}} : <a href="tel:514-608-2297">514-608-2297</a></p>
      </div>
    </div>

    <div class="box">
      <h2>COMPLEXE SPORTIF DE SAINT-LAURENT</h2>
      <div class="leagues">
        <img src="@/assets/contact/COMPLEXE SPORTIF DE SAINT-LAURENT.jpg" />
        <div class="league-info">
          <p>{{$t("contactUs.fieldType")}}: Indoor Turf</p>
          <p>
            <a href="https://goo.gl/maps/mB5F1FvXyAiPBuQt6" target="_blank">2385 Boulevard Thimens,
            <br />Saint-Laurent, QC H4R 1T4
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <h2>PARC BERTHIAUME-DU TREMBLAY</h2>
      <div class="leagues inverted">
        <img src="@/assets/contact/BERTHIAUME-DU TREMBLAY.jpg" />
        <div class="league-info">
          <p>{{$t("contactUs.fieldType")}}: Outdoor Turf</p>
          <p>
            <a href="https://goo.gl/maps/CwSfLeQ4f2RdDN4R7" target="_blank">
            4250 Boulevard Lévesque O,
            <br />Laval, QC H7W 2P8
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <h2>COLLÈGE LETENDRE</h2>
      <div class="leagues">
        <img src="@/assets/contact/COLLEGE LETENDRE.jpg" />
        <div class="league-info">
          <p>{{$t("contactUs.fieldType")}}: Outdoor Turf</p>
          <p>
            <a href="https://goo.gl/maps/1oyBu5CZiGu4c8MEA" target="_blank">
            1000 Boulevard de l'Avenir,
            <br />Laval, QC H7N 6J6
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <h2>COLLÈGE BOIS-DE-BOULOGNE</h2>
      <div class="leagues inverted">
        <img src="@/assets/contact/COLLEGE BOIS DE BOULOGNE.webp" />
        <div class="league-info">
          <p>{{$t("contactUs.fieldType")}}: Outdoor Turf</p>
          <p>
            <a href="https://goo.gl/maps/gswzzvvba9Y5wvEn9" target="_blank">
            10555 Ave de Bois-de-Boulogne,
            <br />Montreal, Quebec H4N 1L4
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XNav from "@/components/XNav.vue";
export default {
  components: {
    XNav
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.leagues {
  display: flex;
  flex-direction: column;

  & > img {
    width: 100%;
    height: auto;
  }
}
.league-info {
  @extend %secondary-pattern;
  color: white;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-size: 25px;
    text-transform: uppercase;
  }
  h3,
  p {
    margin: 15px;
    font-size: 20px;
  }
  a{
    color:white;
  }
}
.contact-info{
  background:$secondary;
  color:white;
  padding: 15px;
  p{
    margin: 7.5px 0px;
    a{
      color: white;
    }
  }
}
@media only screen and (min-width: $mobile-breakpoint) {
  .leagues {
    flex-direction: row;
    & > img {
      width: 50%;
    }
    &.inverted {
      flex-direction: row-reverse;
      text-align: right;
    }
    & > * {
      flex-basis: 50%;
    }
  }
}
</style>